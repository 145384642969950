import { CrmSafeAny } from 'common-module/core/types';

import { AppointmentEventStatus } from './appointment-event.model';
import { EventModel } from './event.model';

export type EventXProp = {
  name: EventXPropType;
  value: CrmSafeAny;
};

export type EventXPropType =
  | 'CLIENT'
  | 'PATIENT'
  | 'STAFF'
  | 'ROOMS'
  | 'EQUIPMENT'
  | 'STARTTIME'
  | 'ENDTIME'
  | 'REASON'
  | 'NOTIFICATION';

export const findXProp = (
  event: Pick<EventModel, 'xProps'>,
  xProp: EventXPropType,
): string => {
  return event?.xProps?.find((x) => x.name === xProp)?.value;
};

export const findXPropArray = (
  event: Pick<EventModel, 'xProps'>,
  xProp: EventXPropType,
): string[] => {
  return findXProp(event, xProp)?.split(',') ?? [];
};

export const updateXPropTimes = (
  xProps: EventXProp[],
  status: AppointmentEventStatus,
) => {
  switch (status) {
    case 'STARTED':
      return setXPropTime(xProps, 'STARTTIME');
    case 'COMPLETED':
      return setXPropTime(xProps, 'ENDTIME');
    default:
      return xProps;
  }
};

export const setXPropTime = (
  xProps: EventXProp[],
  xProp: EventXPropType,
): EventXProp[] => {
  const newProps = (xProps || []).filter((x) => x.name !== xProp);
  newProps.push({ name: xProp, value: new Date().toISOString() });
  return newProps;
};
